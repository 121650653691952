import particService from "../services/participant.service"
import eventService from "../services/event.service"
import swal from "sweetalert";


export const actions = (modifier,serviceName) => {
    let service = null ;
    if(serviceName === "participant") service = particService ;
    if(serviceName === "event") service = eventService ;
    function append(data) {
        modifier(_ => [..._, { ...data }])
    }
    function prepend(data) {
        modifier(_ => [{...data }, ..._])
    }
    async function remove(id) {
            
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then( async (willDelete) => {
            if (willDelete) {
               await service.delete(id);
               modifier(  _ => {
                return _.filter(el => el._id !== id)
            }) 
              swal("Poof! Deleted!", {
                icon: "success",
              });
            } else {
              swal("Your are safe!",{icon : "success"});
            }
          });


            




    }
    async function modifyById(id, newData) {
        let data = null ;
        modifier( _ => {
            for (let index = 0; index < _.length; index++) {
                const element = _[index];
                if(element._id === id) {
                     data = Object.assign({},element,newData)  
                    _[index] = data ;
                    break ;
                }
            }
            return [..._] ;
        });     
        if(data !== null) await service.update(data._id,newData);

    }

    async function modifyByIndex(i, newData) {

        let data = null ;
        modifier( _ => {
            let arr = _.map((el,index) => {
                if(index === i) {
                    data = Object.assign({},el,newData)  
                    return data;
                }
                return el ;
            })
            return arr  ;
        });     
       if(data !== null) await service.update(data._id,newData);
    }  
    return {
        append,
        prepend,
        remove,
        modifyById,
        modifyByIndex
    }

}