import { ErrorMessage } from "@hookform/error-message";

export const userDefaultInformation = {
  nom: "",
  prenom: "",
  email: "",
  structurename: "",
  num_finess: "",
};
export function FriendInfo({ register, k, errors }) {
  return (
    <>
      <h3>
        Veuillez saisir les informations de la personne que vous souhaitez
        ajouter.
      </h3>
      <p
        style={{
          color: "#005978",
          fontFamily: " 'Futura PT', sans-serif'",
          textAlign: "left",
          marginLeft: ".75rem",
          marginTop: "-1rem",
        }}
      >
        <strong>
          {" "}
          Pour une expérience individuelle, veuillez inscrire une adresse mail
          différente par personne.
        </strong>
      </p>
      <div className="form-group">
        {/* <label htmlFor={`friend.${k}.nom`}>Nom *</label> */}
        <input
          type="text"
          className="form-control"
          {...register(`friend.${k}.nom`, {
            required: "this felid is required",
          })}
          id={`friend.${k}.nom`}
          placeholder="Nom*"
        />
        <ErrorMessage
          errors={errors}
          name={`friend.${k}.nom`}
          as="p"
          className="message-error"
        />
      </div>

      <div className="form-group">
        {/* <label htmlFor={`friend.${k}.prenom`}>Prenom *</label> */}
        <input
          type="text"
          className="form-control"
          {...register(`friend.${k}.prenom`, {
            required: "this felid is required",
          })}
          id={`friend.${k}.prenom`}
          placeholder="Prenom*"
        />
        <ErrorMessage
          errors={errors}
          name={`friend.${k}.prenom`}
          as="p"
          className="message-error"
        />
      </div>
      <div className="form-group">
        {/* <label htmlFor={`friend.${k}.email`}>Email *</label> */}
        <input
          type="email"
          className="form-control"
          {...register(`friend.${k}.email`, {
            required: "this felid is required",
          })}
          id={`friend.${k}.email`}
          placeholder="Email*"
        />
        <ErrorMessage
          errors={errors}
          name={`friend.${k}.email`}
          as="p"
          className="message-error"
        />
      </div>
    </>
  );
}
