import DashBoardEvent from "./DashBoardEvent";
import DashBoardEvents from "./DashBoardEvents";
import DashBoardParticipant from "./DashBoardParticipant";
import DashbordSondage from "./DashbordSondage";

function Dashboard({ path }) {
  switch (path) {
    case "events":
      return <DashBoardEvents />;
    case "participant":
      return <DashBoardParticipant />;
    case "event":
      return <DashBoardEvent />;
    case "sondage":
      return <DashbordSondage />;
    default:
      return <></>;
  }
}

export default Dashboard;
