import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
// import Register from "./components/Register";
import Home from "./components/Home";
// import BoardAdmin from "./components/BoardAdmin";
// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import Dashboard from "./components/dashboard";
import jwt_decode from "jwt-decode";
import PrivateRoutes from "./components/utils/PrivateRoutes";
import Sondageform from "./components/sondage/sondageform";
import { HomeFormdispatch } from "./components/user-form/EventSubscribe";

// const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user'))); //convert to object
if (localStorage["user"]) {
  const user = JSON.parse(localStorage.user);
  const token = user["accessToken"];
  const currentTime = Date.now();
  if (token) {
    const decodedToken = jwt_decode(token);
    if (decodedToken.exp * 1000 < currentTime) {
      AuthService.logout();
      window.location.href = "/login";
    }
  }
}

// for axios
if (localStorage["user"]) {
  const user = JSON.parse(localStorage.user);
  const token = user["accessToken"];
  AuthService.setAuthToken(token);
}

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser({ ...user, isAuth: true });
      // console.log();
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <div className="APP">
      {currentUser && showAdminBoard ? (
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <NavLink
            to={"/"}
            className={({ isActive }) => {
              return `navbar-brand ${isActive ? "active" : "not__active"}`;
            }}
          >
            SmartRx
          </NavLink>
          <div className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to={"/home"}
                className={({ isActive }) => {
                  return `nav-link ${isActive ? "active" : "not__active"}`;
                }}
              >
                Home
              </NavLink>
            </li>
          </div>
          <div className="navbar-nav ">
            <li className="nav-item">
              <NavLink
                to={"dashboard/participant"}
                className={({ isActive }) => {
                  return `nav-link ${isActive ? "active" : "not__active"}`;
                }}
              >
                participants
              </NavLink>
            </li>
          </div>
          <div className="navbar-nav ">
            <li className="nav-item">
              <NavLink
                to={"dashboard/events"}
                className={({ isActive }) => {
                  return `nav-link ${isActive ? "active" : "not__active"}`;
                }}
              >
                events
              </NavLink>
            </li>
          </div>
          <div className="navbar-nav ">
            <li className="nav-item">
              <NavLink
                to={"dashboard/sondage"}
                className={({ isActive }) => {
                  return `nav-link ${isActive ? "active" : "not__active"}`;
                }}
              >
                sondage
              </NavLink>
            </li>
          </div>
          <div className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <NavLink to={"/profile"} className={({isActive}) => {return  `nav-link ${isActive ? 'active' : 'not__active' }`}}>
                {currentUser.username}
              </NavLink>
            </li> */}
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          </div>
        </nav>
      ) : null}

      <div className="">
        <Routes>
          <Route path={"/"} element={<Home />}>
            <Route path={"/home"} element={<HomeFormdispatch />} />
            <Route path={"/sondage"} element={<Sondageform />} />
            <Route index element={<HomeFormdispatch />} />
          </Route>

          <Route path="/login" element={<Login />} />
          {/* <Route  path="/register" element={<Register />} /> */}
          {/* <Route path="/admin" element={<BoardAdmin />} /> */}

          <Route path="*" element={<PrivateRoutes user={currentUser} />}>
            <Route path="dashboard">
              <Route path="events" element={<Dashboard path="events" />} />
              <Route path="event/:id" element={<Dashboard path="event" />} />
              <Route
                path="participant"
                element={<Dashboard path="participant" />}
              />
              <Route path="sondage" element={<Dashboard path="sondage" />} />
            </Route>
          </Route>
        </Routes>
      </div>
      <div></div>

      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
};

export default App;
