import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { formatDate, formatTime } from "../../../utils/date";
import Table from "../TableContainer";
import { actions } from "../../../utils/action";
import { useNavigate } from "react-router-dom";
import EventForm from "../DashBoardEvent/EventForm";

function DeleteButton({onClick}) {

  async function click() {
   onClick();
  }
 return (
 <button className="btn btn-danger" onClick={click}>
 🗑
 </button>)
}

function DashBoardEvents(params) {
    const [data, setData] = useState([]);
    const action = actions(setData,"event");
    let navigate = useNavigate();
    const columns = useMemo(
        () => [
              {
                Header :"Date",
                accessor : ({date_event}) => formatDate(new Date(date_event)) ,
                sortType: (a, b) => {
                  return new Date(b.original["date_event"]) - new Date(a.original["date_event"]) ;
                }
              },
              {
                Header: "Horaires",
                accessor: ({start,end}) => `${start} - ${end}`

              },
              {
                Header: "Nbr Max",
                accessor: "max_place"
              },
              {
                Header: "Nbr participants",
                accessor: "nbre_place"
              },
              {
                Header: "Done",
                accessor: ({done}) => done === undefined ? "0" : done,
                // Cell: (cell) => cell.value || "N/A",
              },
              {
                Header: "action",
                accessor : (a) => a._id,
                disableSortBy : true,
                disableFilters: true,
                Cell: (cell) => (
                  <>
                    <button className="btn btn-primary" onClick={() => navigate("../event/"+cell.value)}>edit</button>
                    {" "}
                    <DeleteButton onClick={() => {action.remove(cell.value)}}/>

                </>
              )}
        ]
      )
    useEffect(() => {
        axios(
          "https://api.smartrealxperience-inscription.fr/api/partic/allChecked"
        )
          .then((res) => {
            setData(Object.values(res.data));
          })
          .catch((err) => console.log(err));
      }, []);
    return (
        <section className="container-fluid">
            <EventForm afterAdd={(inst) => {action.prepend(inst)}} />
            <h2 style={{textAlign : "center"}} >DashBoardEvent</h2>
            <hr />
            <Table columns={columns} data={data} initialState={{ pageIndex: 0 }}
            extra = {{
              autoResetPage: false,
              autoResetExpanded: false,
              autoResetGroupBy: false,
              autoResetSelectedRows: false,
              autoResetSortBy: false,
              autoResetFilters: false,
              autoResetRowState: false,
              autoResetGlobalFilter : false,
            }} />
        </section>
    )
}


export default DashBoardEvents ;