import { ErrorMessage } from "@hookform/error-message";

export const userDefaultInformation = {
  nom: "",
  prenom: "",
  email: "",
  structurename: "",
  num_finess: "",
};
export function UserInformation({ register, errors }) {
  return (
    <>
      <div className="nomprenom">
        <div className="form-group">
          {/* <label htmlFor="nom">Nom *</label> */}
          <input
            type="text"
            className="form-control"
            {...register(`nom`, { required: "Ce champ est requis !" })}
            id="nom"
            placeholder="Nom*"
          />
          <ErrorMessage
            errors={errors}
            name="nom"
            as="p"
            className="message-error"
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="email">Email *</label> */}
          <input
            type="email"
            className="form-control"
            {...register(`email`, { required: "Ce champ est requis !" })}
            id="email"
            placeholder="Email*"
          />
          <ErrorMessage
            errors={errors}
            name="email"
            as="p"
            className="message-error"
          />
        </div>
      </div>
      <div className="email_structure" style={{ marginBottom: "-1rem" }}>
        <div className="form-group">
          {/* <label htmlFor="prenom">Prenom *</label> */}
          <input
            type="text"
            className="form-control"
            {...register(`prenom`, { required: "Ce champ est requis !" })}
            id="prenom"
            placeholder="Prenom*"
          />
          <ErrorMessage
            errors={errors}
            name="prenom"
            as="p"
            className="message-error"
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="structurename">Nom de la structure</label> */}
          <input
            type="text"
            className="form-control"
            {...register(`structurename`)}
            id="structurename"
            placeholder="Nom de la pharmacie"
          />
          <ErrorMessage
            errors={errors}
            name="structurename"
            as="p"
            className="message-error"
          />
        </div>
      </div>
      <div
        className="email_structure"
        style={{ marginBottom: "-1rem", marginTop: "1rem" }}
      >
        <div className="form-group">
          {/* <label htmlFor="prenom">Prenom *</label> */}
          <input
            type="text"
            className="form-control"
            {...register(`num_finess`, { required: "Ce champ est requis !" })}
            id="num_finess"
            placeholder="N°FINESS*"
            minLength={9}
            maxLength={9}
            pattern={"[0-9]+"}
          />
          <ErrorMessage
            errors={errors}
            name="num_finess"
            as="p"
            className="message-error"
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="prenom">Prenom *</label> */}
          <input
            type="text"
            className="form-control"
            {...register(`nom_du_groupement`)}
            id="nom_du_groupement"
            placeholder="Nom du groupement"
          />
        </div>
      </div>
    </>
  );
}
