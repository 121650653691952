import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import EventService from "../../../services/event.service";
import { ErrorMessage } from "@hookform/error-message";

export default function EventForm({
  id,
  afterAdd = (data) => {},
  buttonText = "Submit",
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setForm,
  } = useForm();
  const [data, setData] = useState({});
  const onSubmit = (data) => {
    if (id) {
      EventService.update(id, data).then((res) => {
        afterAdd(res.data);
      });
    } else {
      EventService.create(data).then((res) => {
        afterAdd(res.data);
      });
    }
  };

  useEffect(() => {
    if (id) {
      axios(`https://api.smartrealxperience-inscription.fr/api/event/${id}`)
        .then((res) => {
          setForm("date_event", res.data.date_event);
          setForm("start", res.data.start);
          setForm("end", res.data.end);
          setForm("max_place", res.data.max_place);
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const [value, setValue] = useState("");
  const [debut, setDebut] = useState("");
  const [fin, setFin] = useState("");
  const handleChange = (e) => {
    setValue((v) => (e.target.validity.valid ? e.target.value : v));
  };
  return (
    <>
      <form className="container-fluid pt-4 pb-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row align-items-center">
          <div className="form-group col-md-3">
            <label className="text-center">Date</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Le Jour
                </span>
              </div>
              <input
                type="date"
                defaultValue={data["date_event"]}
                className="form-control text-center"
                {...register("date_event", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>

          <div className="form-group col-md-3">
            <label className="text-center">Horaires</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Debut
                </span>
              </div>
              <input
                type="time"
                defaultValue={data["start"]}
                className="form-control text-center"
                onInput={(e) => {
                  setDebut(e.target.value);
                }}
                placeholder="debut"
                {...register("start", { required: "this field is required" })}
              />
            </div>
          </div>

          <div className="form-group col-md-3">
            <label className="text-center">Horaires</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Fin
                </span>
              </div>
              <input
                type="time"
                defaultValue={data["end"]}
                className="form-control text-center"
                placeholder="fin"
                onInput={(e) => {
                  setFin(e.target.value);
                }}
                min={debut}
                {...register("end", { required: "this field is required" })}
              />
            </div>
          </div>

          <div className="form-group col-md-3">
            <label className="text-center">nombre max</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  place
                </span>
              </div>
              <input
                onChange={handleChange}
                defaultValue={data["max_place"]}
                min="0"
                type="number"
                pattern="[0-9]*"
                step="10"
                className="form-control text-center"
                placeholder="place"
                {...register("max_place", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>
        </div>
        <div className="form-row align-items-center">
          <div className="col-md-3 m-auto text-center">
            <input
              className="btn btn-primary m-auto"
              type="submit"
              value={buttonText}
            />
          </div>
        </div>
      </form>
    </>
  );
}
