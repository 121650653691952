import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const PrivateRoutes = () => {
      let user = AuthService.getCurrentUser();
      if (user) {
        user = { ...user, isAuth: true };
      }

  let {isAuth} = {...user} ;

  return (
    isAuth ? <Outlet/> : <Navigate to={"/"}/>
  )
}


export default PrivateRoutes;