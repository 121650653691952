import { paginationDotsRange } from "../utils/range";

export function Pagination({canPreviousPage,previousPage,pageIndex,gotoPage,pageCount,canNextPage,nextPage}) {
    if(pageCount === 1) return null ;
    return (
        <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">


          <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} >
            <a onClick={() => previousPage()} className="page-link" href="#table">Previous</a>
          </li>
          {
            paginationDotsRange(pageIndex + 1,pageCount).map(el => {
               return <li key={el} className= {`page-item ${ (el - 1 === pageIndex) ? "active" : "" }`}>
                    <a onClick={() => gotoPage(el - 1)} className="page-link" href="#table">{el}</a>
                </li>
            })
          }
          <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
            <a onClick={() => nextPage()} className="page-link" href="#table">Next</a>
          </li>

        </ul>
      </nav>
    ) 
}