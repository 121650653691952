import React, { useState, useEffect } from "react";
// import { HomeForm } from "./user-form/EventSubscribe";
import logo from "../assets/images/logo_smartRx_CMJN sans baseline.png";
// import EventService from "../services/event.service";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import swal from "sweetalert2";
import { document } from "./DocumentContent";

const Home = () => {
  const location = useLocation();
  console.log(location);
  const popupActivate = () => {
    swal.fire({
      // title: "<strong>HTML <u>example</u></strong>",
      // icon: "info",
      html: document,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showConfirmButton: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      // confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      // confirmButtonAriaLabel: "Thumbs up, great!",
      // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      // cancelButtonAriaLabel: "Thumbs down",
    });
  };
  return (
    <>
      {" "}
      <div
        className="homepage_screen"
        style={location.pathname == "/sondage" ? { height: "100vh" } : null}
      >
        <div className="blur"></div>
        <div className="header">
          <div className="block1"></div>
          <div className="block2">
            <h3 onClick={popupActivate}>A propos</h3>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="formcontainer mt-3">
          <div className="Home__screen">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
