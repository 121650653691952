import { Card,Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export function FormsEvent({children}) {
    return <Accordion defaultActiveKey="0">
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          Update Event
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
                {children[0]}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="1">
          Add New Participent
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
                {children[1]}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
}