import axios from "axios";

const API_URL = "https://api.smartrealxperience-inscription.fr/api";

// const token = JSON.parse(localStorage.getItem("user")).accessToken;
const create = (data) => {
  return axios.post(API_URL + "/partic/create", data);
};
const getAllParticipants = () => {
  return axios.get(API_URL + "/partic/all");
};
const get_All_ParticipantByEventID = (eventID) => {
  return axios.get(API_URL + `/partic_event/all?eventID=${eventID}`);
};
const getParticipant = (id) => {
  return axios.get(API_URL + `/partic/${id}`);
};
const UpdateParticipant = (id, data) => {
  return axios.put(API_URL + `/partic/${id}`, data);
};
const DeleteParticipant = (id) => {
  return axios.delete(API_URL + `/partic/${id}`);
};

const particService = {
  create,
  getOne:getParticipant,
  getAll:getAllParticipants,
  update:UpdateParticipant,
  delete:DeleteParticipant,
  get_All_ParticipantByEventID,
};

export default particService;
