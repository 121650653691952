import React from "react";
import { useForm } from "react-hook-form";
import particService from "../../../services/participant.service";
import swal from "sweetalert";

export default function AddParticipant({ buttonText, id, action }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const User = Object.assign(data, { event: id }, { friend: [] });
    particService.create(User).then((user) => {
      action.prepend(user.data);
      reset();
    });

    //acctioons ? ?
    // swal({
    //   title: "Votre demande d'inscription à bien été prise en compte.",
    //   text: `Weell done`,
    //   icon: "success",
    // }).then(() => {
    //   reset();
    //   window.location.reload();
    // });

    // if (id) {
    //   EventService.update(id, data).then((res) => {
    //     afterAdd(res.data);
    //   });
    // } else {
    //   EventService.create(data).then((res) => {
    //     afterAdd(res.data);
    //   });
    // }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="container-fluid pt-4 pb-4"
      >
        <div className="form-row align-items-center justify-content-around ">
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Nom
                </span>
              </div>
              <input
                type="text"
                className="form-control text-center"
                {...register("nom", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Prenom
                </span>
              </div>
              <input
                type="text"
                className="form-control text-center"
                {...register("prenom", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Email
                </span>
              </div>
              <input
                type="email"
                className="form-control text-center"
                {...register("email", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Pharmacie
                </span>
              </div>
              <input
                type="text"
                className="form-control text-center"
                {...register("structurename", {
                  required: "this field is required",
                })}
              />
            </div>
          </div>
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  N° Finess
                </span>
              </div>
              <input
                type="text"
                className="form-control text-center"
                {...register("num_finess", {
                  required: "this field is required",
                })}
                minLength={9}
                maxLength={9}
              />
            </div>
          </div>
          <div className="form-group col-md-4 col-sm-6 ">
            {/* <label className="text-center">Nom</label> */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Nom du groupement
                </span>
              </div>
              <input
                type="text"
                className="form-control text-center"
                {...register("nom_du_groupement")}
              />
            </div>
          </div>
        </div>
        <div className="form-row align-items-center">
          <div className="col-md-4 col-sm-6  m-auto text-center mb-4">
            <input
              className="btn btn-primary text-center"
              type="submit"
              value={buttonText}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
