function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  export function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  export function formatTime(date) {
    return [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes())
    ].join(":")
  }

  export function compareWithToday(date,time) {
    let Eventhours = time.split(":");
    let g1 = new Date();
    let g2 = new Date(date);
    g2.setHours(Eventhours[0], Eventhours[1]);
    if (g1.getTime() < g2.getTime()) return 1;
    else if (g1.getTime() > g2.getTime()) return -1;
    else  return 0;
  }
  export function compareTwodates(date1,time1 ,date2,time2) {
    let Eventhours1 = time1.split(":");
    let Eventhours2 = time2.split(":");
    let g1 = new Date(date1);
    let g2 = new Date(date2);
    g1.setHours(Eventhours1[0], Eventhours1[1]);
    g2.setHours(Eventhours2[0], Eventhours2[1]);
    if (g1.getTime() > g2.getTime()) return 1;
    else if (g1.getTime() < g2.getTime()) return -1;
    else  return 0;
  }