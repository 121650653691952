import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import SondageService from "../../../services/sondage.service";
import { actions } from "../../../utils/action";
import { formatDate, formatTime } from "../../../utils/date";
import Table from "../TableContainer";

function DashBoardSondage(params) {
  const [data, setData] = useState([]);
  const action = actions(setData, "participant");

  const columns = useMemo(() => [
    {
      Header: "Date de creation",
      accessor: ({ createdAt }) =>
        formatDate(new Date(createdAt)) +
        " - " +
        formatTime(new Date(createdAt)),
      sortType: (a, b) => {
        return new Date(b.original.createdAt) - new Date(a.original.createdAt);
      },
    },
    {
      Header: "Nom prenom",
      accessor: "nom_prenom",
    },
    {
      Header: "question1",
      accessor: ({ question1 }) => (question1 ? "oui" : "non"),
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      Header: "question2",
      accessor: ({ question2 }) => (question2 ? "oui" : "non"),
      // Cell: (cell) => cell.value || "N/A",
    },
  ]);
  useEffect(() => {
    SondageService.getAll().then((res) => {
      console.log(res.data);
      setData(res.data);
    });
    // axios("https://api.smartrealxperience-inscription.fr/api/partic/all")
    //   .then((res) => {
    //     setData(res.data);
    //     // console.log(res.data);
    //   })
    //   .catch((err) => console.log(err));
  }, []);
  return (
    <section className="container-fluid">
      <h2 style={{ textAlign: "center" }}>DashBoard Sondage</h2>
      <hr />
      <Table
        columns={columns}
        data={data}
        initialState={{ pageIndex: 0 }}
        extra={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetGroupBy: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          autoResetRowState: false,
          autoResetGlobalFilter: false,
        }}
      />
    </section>
  );
}

export default DashBoardSondage;
