import axios from "axios";

const API_URL = "https://api.smartrealxperience-inscription.fr/api/event";

// const token = JSON.parse(localStorage.getItem("user")).accessToken;
const createEvent = (data) => {
  return axios.post(API_URL + "/create", data);
};
const getAllevents = () => {
  return axios.get(API_URL + "/all");
};
const getEvent = (id) => {
  return axios.get(API_URL + `/${id}`);
};
const UpdateEvent = (id, data) => {
  return axios.put(API_URL + `/${id}`, data);
};
const DeleteEvent = (id) => {
  return axios.delete(API_URL + `/${id}`);
};

const EventService = {
  create:createEvent,
  get:getEvent,
  getAll:getAllevents,
  update:UpdateEvent,
  delete:DeleteEvent,
};

export default EventService;
