import { toNormalForm } from "./normalize"

export const textFilterCustom = (rows, ids, filterValue) => {
    rows = rows.filter(row => {
      return ids.some(id => {
        const rowValue = toNormalForm(row.values[id]);
        return String(rowValue)
          .toLowerCase()
          .includes(String(filterValue).toLowerCase())
      })
    })
    return rows
  }