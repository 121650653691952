import axios from "axios";

const API_URL = "https://api.smartrealxperience-inscription.fr/api/sondage";

// const token = JSON.parse(localStorage.getItem("user")).accessToken;
const createSondage_User = (data) => {
  return axios.post(API_URL + "/create", data);
};
const getAllusersInSondage = () => {
  return axios.get(API_URL + "/all");
};
// const getEvent = (id) => {
//   return axios.get(API_URL + `/${id}`);
// };
// const UpdateEvent = (id, data) => {
//   return axios.put(API_URL + `/${id}`, data);
// };
const DeleteSondage_User = (id) => {
  return axios.delete(API_URL + `/${id}`);
};

const SondageService = {
  create: createSondage_User,
  getAll: getAllusersInSondage,
  delete: DeleteSondage_User,
};

export default SondageService;
