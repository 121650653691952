import { useEffect, useState } from "react";
import { compareTwodates, compareWithToday, formatDate, formatTime } from "../../utils/date";
import { useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export function EventsForm({
  register,
  Listevents,
  getValues,
  control,
  errors,
  setValue
}) {
  useWatch({
    control,
    name: "event",
  });
  useWatch({
    control,
    name : "friend",
  })
  // console.table( 'BeforeSorted', Listevents);
  const SortedArray = Listevents.sort(function (a,b) {
    // var c = new Date(a.date_event);
    // var d = new Date(b.date_event);
    // return c - d;
    return compareTwodates(a.date_event,a.start,b.date_event,b.start)

  });
  // console.table(SortedArray);
  return (
    <>
      <div className="row">
        {SortedArray.map((ev) => {
          return (
            <Event
              register={register}
              checked={getValues()["event"] === ev._id}
              key={ev._id}
              date={ev.date}
              date_event={ev.date_event}
              id={ev._id}
              name={ev.name}
              maxPlace={ev.max_place}
              occupied={ev.nbre_place}
              start={ev.start}
              end={ev.end}
              booking={getValues()["friend"].length + 1}
              reset={() => {
                setValue("event", null);
              }}
            />
          );
        })}
      </div>
      <ErrorMessage
        errors={errors}
        name={"event"}
        as="div"
        className="message-error"
      />
    </>
  );
}

function Event({
  id,
  date = "Date 24/5",
  date_event,
  name = "Event Name",
  maxPlace,
  occupied,
  start,
  end,
  register,
  checked,
  booking,
  reset
}) {


  const disabled = maxPlace - occupied <= 0 || compareWithToday(date_event,start) < 0 ;
  useEffect(() => {

    if(checked && booking > maxPlace - occupied){
      reset()
    }
  },[checked,booking,reset,maxPlace,occupied])

  return (
    <div className="Eventboxes form-check col-md-3 col-sm-12 col-xs-12">
      <input
        className="form-check-input"
        type="radio"
        id={id}
        hidden
        value={id}
        disabled={disabled}

        {...register("event", {
          required: "vous devez au moins sélectionner un événement !",
        })}
      />
      <label className="form-check-label" htmlFor={id}>
        <div
          className={`card text-center eventbox ${checked ? "eventchecked" : "event_nonchecked"} ${disabled ? "disabled" : "enabled"} ${booking > maxPlace - occupied ? "notAvailable" :"available"}`}
        >
          <div className="card-body">
            {/* <h5 className="card-title">{name}</h5> */}
            <p className="card-text">{formatDate(new Date(date_event))}</p>
            {/* <p className="card-text">{date_event}</p> */}
            <p className="card-text">{`${start} - ${end}`}</p>
            <p className="card-text">{maxPlace - occupied} places restantes</p>
            {/* <p>booking {booking} places</p> */}
          </div>
        </div>
      </label>
    </div>
  );
}
