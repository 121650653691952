import React from "react";
import { useFieldArray } from "react-hook-form";
import { userDefaultInformation } from "./EventSubscribe";
import { FriendInfo } from "./friendInfo";
import plusbutton from "../../assets/images/Body-btn+.png";

export function NestedFiledArray({ control, register, errors }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `friend`,
  });

  return (
    <div className="row  align-items-center ">
      {fields.map((item, k) => {
        return (
          <div className="col-sm-6">
            <div className="card text-center mb-2">
              <div className="card-body p-3">
                <div
                  type="button"
                  className="removefriend"
                  onClick={() => remove(k)}
                ></div>
                <FriendInfo errors={errors} register={register} k={k} />
              </div>
            </div>
          </div>
        );
      })}

      <div className="col-sm-6">
        <div
          type="button"
          className="Inviter_partic"
          onClick={() => append(userDefaultInformation)}
        >
          Inviter un participant
          <img src={plusbutton}></img>
        </div>
      </div>
    </div>
  );
}
