import React, { useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { Pagination } from "../Pagination";
import { CSVLink } from "react-csv";
import { toNormalForm } from "../../utils/normalize";
import { textFilterCustom } from "../../utils/filter";
import { useEffect } from "react";
function colorRow(original) {
  if (original["checked"]) return "table-success";
  if (
    original["done"] !== undefined &&
    original["nbre_place"] !== 0 &&
    original["nbre_place"] === original["done"]
  )
    return "table-success";
}
const ReactTable = ({ columns, data, initialState, extra }) => {
  const tableInstance = useTable(
    { columns, data, initialState, ...extra, globalFilter: textFilterCustom },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;
  return (
   // apply the table props
   <div className="table-responsive">
   <table id="table" className="table table-bordered table-hover table-sm text-center" {...getTableProps()}>
     <thead>
     <tr>
          <th
            colSpan={visibleColumns.length  }
          >
            <div className="container-fluid">
            <div className="row">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <RowsNumber setPageSize={setPageSize}/>
            <CSVLink className="btn btn-primary ml-auto" data={rows.map(({values}) => {delete values._id ;return values})}>Download CSV</CSVLink>
            </div>
            </div>
          </th>

        </tr>
        <tr>
          <th
            colSpan={visibleColumns.length  }
          >
            <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-auto"> total : <span className="badge badge-info">{rows.length}</span></div>
              <div className="col-auto"> shows : <span className="badge badge-info">{page.length}</span> </div>
            </div>
            </div>
          </th>

        </tr>
       {// Loop over the header rows
       headerGroups.map(headerGroup => (
         // Apply the header row props
         <tr {...headerGroup.getHeaderGroupProps()}>
           {// Loop over the headers in each row
           headerGroup.headers.map(column => (
             // Apply the header cell props
             <th  {...column.getHeaderProps(column.getSortByToggleProps())}>
               {// Render the header
               column.render('Header')}
                                 <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? '⮇'
                        : '⮅'
                      : '⇅'}
                  </span>
             </th>
           ))}
         </tr>
       ))}
     </thead>
     {/* Apply the table body props */}
     <tbody {...getTableBodyProps()}>
       {// Loop over the table rows
       page.map(row => {
         // Prepare the row for display
         prepareRow(row)
         return (
           // Apply the row props
           <tr className={colorRow(row.original)} {...row.getRowProps()}>
             {// Loop over the rows cells
             row.cells.map(cell => {
               // Apply the cell props
               return (
                 <td className="align-middle" {...cell.getCellProps()}>
                   {// Render the cell contents
                   cell.render('Cell')}
                 </td>
               )
             })}
           </tr>
         )
       })}
     </tbody>
   </table>
    <Pagination
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        canNextPage={canNextPage}
        previousPage={previousPage}
      />
    </div>
  );
};

export default ReactTable;

const TWO_HUNDRED_MS = 200;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  data,
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    let normValue = toNormalForm(value);

    setGlobalFilter(normValue || undefined);
  }, TWO_HUNDRED_MS);

  return (
    <div className="col-3">
      <input
        className="form-control"
        type="text"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search`}
      />
    </div>
  );
}

function RowsNumber({ setPageSize }) {
  useEffect(() => {
    setPageSize(20);
  }, []);
  return (
    <div className="col-2">
          <select defaultValue={'20'} onChange={(el) => {setPageSize(parseInt(el.target.value))}} className="form-control">
          <option value="10">10</option>
          <option  value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="80">80</option>
          <option value="130">130</option>
          </select>

    </div>
  );
}
