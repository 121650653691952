import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SondageService from "../../services/sondage.service";
import NEV from "../../assets/images/NEV.png";
import btninscript from "../../assets/images/Body-btn-inscription-fleche.png";
import swal from "sweetalert";

export default function Sondageform() {
  const defaultValues = {
    nom_prenom: "",
    question1: "",
    question2: "",
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });
  const onSubmit = (data) => {
    SondageService.create(data)
      .then(() => {
        swal({
          title: "Vos réponses ont bien été prises en compte.",
          text: `Vous pouvez fermer cette page maintenant.`,
          icon: "success",
        }).then(() => {
          reset();
          window.location.reload();
        });
      })
      .catch((err) => {
        swal({
          title: "Oups, un problème est survenu !",
          text: "Veuillez rafraîchir la page",
          icon: "error",
        }).then(() => {
          reset();
          window.location.reload();
        });
        console.log(err.message);
        // err.message || "Some error occurred while creating this User."
      });
  };
  return (
    <>
      <main className="sondage">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="">
            Merci d'avoir participé à la{" "}
            <span style={{ color: "#005978" }}>Smart Real Xperience!</span>
          </h1>
          <p className="bar"></p>
          <h3>Merci d'indiquer votre nom et prénom * : </h3>

          {/* <UserInformation errors={errors} register={register} /> */}
          <div className="nom_prenom">
            <div className="form-group">
              {/* <label htmlFor="nom">Nom *</label> */}
              <input
                type="text"
                className="form-control"
                {...register(`nom_prenom`, {
                  required: "Ce champ est requis !",
                })}
                id="nom"
                placeholder="Nom et Prenom*"
              />
              <ErrorMessage
                errors={errors}
                name="nom_prenom"
                as="p"
                className="message-error"
              />
            </div>
          </div>
          <br />
          <p className="bar"></p>
          <h3>
            Êtes-vous prêt à mettre en place le{" "}
            <img src={NEV} style={{ width: "60px", marginTop: "-15px" }}></img>
            dans votre pharmacie ? *
          </h3>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              {...register("question1", {
                required: "Veuillez vérifier ce champ !",
              })}
              id="true1"
              value="true"
            />
            <label className="form-check-label" htmlFor="true1">
              <strong>Oui</strong>
            </label>
            <input
              className="form-check-input"
              type="radio"
              {...register("question1", {
                required: "Veuillez vérifier ce champ !",
              })}
              id="false1"
              value="false"
            />
            <label className="form-check-label" htmlFor="false1">
              <strong>Non</strong>
            </label>
            <ErrorMessage
              errors={errors}
              name="question1"
              as="p"
              className="message-error"
            />
          </div>
          <p className="bar"></p>
          <h3>
            Souhaitez-vous être recontacté par votre commercial pour avoir plus
            d'informations ? *
          </h3>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              {...register("question2", {
                required: "Veuillez vérifier ce champ !",
              })}
              id="true2"
              value="true"
            />
            <label className="form-check-label" htmlFor="true2">
              <strong>Oui</strong>
            </label>
            <input
              className="form-check-input"
              type="radio"
              {...register("question2", {
                required: "Veuillez vérifier ce champ !",
              })}
              id="false2"
              value="false"
            />
            <label className="form-check-label" htmlFor="false2">
              <strong>Non</strong>
            </label>
            <ErrorMessage
              errors={errors}
              name="question2"
              as="p"
              className="message-error"
            />
          </div>

          <br />
          <div className="subscribe_btn">
            <button
              type="submit"
              className=" subscribebtn col-md-2 col-sm-12 col-xs-12 "
            >
              Envoyer
              <img src={btninscript} alt=""></img>
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
