import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { EventsForm } from "./EventsForm";
import { NestedFiledArray } from "./nestedFieldArray";
import { UserInformation } from "./UserInformation";
import { ErrorMessage } from "@hookform/error-message";
import particService from "../../services/participant.service";
import btninscript from "../../assets/images/Body-btn-inscription-fleche.png";
import swal from "sweetalert";
import EventService from "../../services/event.service";
import { useState } from "react";
import { useEffect } from "react";

export const userDefaultInformation = {
  nom: "",
  prenom: "",
  email: "",
  structurename: "",
  num_finess: "",
  nom_du_groupement: "",
};
const defaultValues = {
  friend: [],
  acceptWebinar: false,
  acceptInfo: false,
  event: null,
};
export const HomeFormdispatch = () => {
  const [Listevents, setListevents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const result = await EventService.getAll();
      setListevents(result.data);
    };
    fetchEvents();
  }, []);
  return <HomeForm Listevents={Listevents} />;
};

export function HomeForm({ Listevents }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://tarteaucitron.io/load.js?domain=smartrealxperience-inscription.fr&uuid=efb74bb5341e1ddb85e7bb7979c9ca9ff895cb01";
    // script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });
  const onSubmit = (data) => {
    particService
      .create(data)
      .then(() => {
        swal({
          title: "Votre demande d'inscription a bien été prise en compte.",
          text: `Vous recevrez votre lien d'invitation sous 48h.\n N'oubliez pas de vérifier vos mails et spams si besoin.\n A très vite dans la Smart Real Xperience !`,
          icon: "success",
        }).then(() => {
          reset();
          window.location.reload();
        });
      })
      .catch((err) => {
        swal({
          title:
            "Oups, le créneau que vous avez choisis a été réservé entre temps.",
          text: "Veuillez en choisir un nouveau.",
          icon: "error",
        }).then(() => {
          reset();
          window.location.reload();
        });
        console.log(err.message);
        // err.message || "Some error occurred while creating this User."
      });
  };
  return (
    <>
      <main>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="">
            Smart Real Xperience : Inscription session interactive NeV
          </h1>
          <p className="bar"></p>
          <h3>Je remplis mes informations </h3>
          <UserInformation errors={errors} register={register} />
          <br />
          <p className="bar"></p>
          <h3>
            Je souhaite que la, le ou les collègue(s) de ma pharmacie
            participent également :{" "}
          </h3>
          <NestedFiledArray
            {...{
              control,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
            }}
          />
          <p className="bar"></p>
          <h3>
            Je souhaite m'inscrire pour la session du* : <br />
            <br />
            De nouveaux créneaux sont rajoutés régulièrement.{" "}
          </h3>
          <EventsForm
            Listevents={Listevents}
            {...{
              control,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
            }}
          />

          <hr />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("acceptWebinar", {
                required: "Veuillez vérifier ce champ !",
              })}
              id="defaultCheck1"
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              J'accepte de recevoir des informations dans le cadre des sessions
              interactives Smart Real Xperience.*
            </label>
            <ErrorMessage
              errors={errors}
              name="acceptWebinar"
              as="p"
              className="message-error"
            />
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("acceptInfo")}
              id="defaultCheck2"
            />
            <label className="form-check-label" htmlFor="defaultCheck2">
              J'accepte de recevoir des informations de Smart Rx.
            </label>
          </div>
          <br />
          <div className="subscribe_btn">
            <button
              type="submit"
              className=" subscribebtn col-md-2 col-sm-12 col-xs-12 "
            >
              Je m'inscris
              <img src={btninscript} alt=""></img>
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
