import { useParams } from "react-router-dom";
import EventForm from "./EventForm";

import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { actions } from "../../../utils/action";
import Table from "../TableContainer";
import AddParticipant from "./AddParticipant";
import { FormsEvent } from "./FormsEvent";

function DeleteButton({ onClick }) {
  const [deleteing, setDeleting] = useState(false);

  async function click() {
    if (deleteing === true) return;
    setDeleting(true);
    onClick();
  }
  return (
    <button className="btn btn-danger" onClick={click}>
      {deleteing && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {!deleteing && "🗑️"}
    </button>
  );
}

function DashBoardEvent(params) {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [isVisible, setisVisible] = useState(false);
  const action = actions(setData, "participant");

  const columns = useMemo(() => [
    {
      Header: "Nom",
      accessor: "nom",
    },
    {
      Header: "Prénom",
      accessor: "prenom",
    },
    {
      Header: "Adresse mail",
      accessor: "email",
    },
    {
      Header: "Pharmacie",
      accessor: ({ structurename }) => structurename || "N/A",
    },
    {
      id: "num",
      Header: "N FINESS",
      accessor: "num_finess",
    },
    {
      id: "nom_du_groupement",
      Header: "Nom du groupement",
      accessor: ({ nom_du_groupement }) => nom_du_groupement || "N/A",
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      Header: "checked",
      accessor: "checked",
      sortType: (a, b, id) => {
        if (a.original[id] > b.original[id]) return -1;
        if (b.original[id] > a.original[id]) return 1;
      },
      disableGlobalFilter: true,
      Cell: (cell) => (
        <input
          readOnly
          type="checkbox"
          onClick={(e) => {
            action.modifyByIndex(cell.row.index, { checked: !cell.value });
          }}
          checked={cell.value}
        />
      ),
    },
    {
      Header: "action",
      accessor: (a) => a._id,
      disableSortBy: true,
      disableFilters: true,
      Cell: (cell) => (
        <>
          <DeleteButton
            onClick={() => {
              action.remove(cell.value);
            }}
          />
        </>
      ),
    },
  ]);
  useEffect(() => {
    axios(
      `https://api.smartrealxperience-inscription.fr/api/partic_event/all?eventID=${id}`
    )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const showform = () => {
    setisVisible(!isVisible);
  };

  return (
    <section className="container-fluid">
      <h1 className="text-center">DashBoardEvent</h1>
      {/* <hr/>
      <h2 className="text-center">Update current event</h2>
      <hr/>
      {isVisible && (

      )}
      <div className="container-fluid mb-1" onClick={showform}>
        {" "}
        <div className="btn btn-primary ml-auto">Add Participant</div>
      </div> */}
      <FormsEvent>
        <EventForm id={id} buttonText={"Update"} />

        <AddParticipant id={id} buttonText={"Ajouter"} action={action} />
      </FormsEvent>
      <hr />
      <Table
        columns={columns}
        data={data}
        initialState={{ pageIndex: 0 }}
        extra={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetGroupBy: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          autoResetRowState: false,
          autoResetGlobalFilter: false,
        }}
      />
    </section>
  );
}

export default DashBoardEvent;
