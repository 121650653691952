import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { actions } from "../../../utils/action";
import { formatDate, formatTime } from "../../../utils/date";
import Table from "../TableContainer";
function DeleteButton({ onClick }) {
  async function click() {
    onClick();
  }
  return (
    <button className="btn btn-danger" onClick={click}>
      🗑
    </button>
  );
}
function DashBoardParticipant(params) {
  const [data, setData] = useState([]);
  const action = actions(setData, "participant");

  const columns = useMemo(() => [
    {
      Header: "Date d'inscription",
      accessor: ({ createdAt }) =>
        formatDate(new Date(createdAt)) +
        " - " +
        formatTime(new Date(createdAt)),
      sortType: (a, b) => {
        return new Date(b.original.createdAt) - new Date(a.original.createdAt);
      },
    },
    {
      Header: "Date Event",
      accessor: ({ event }) =>
        event ? formatDate(new Date(event["date_event"])) : "Event not found",
      sortType: (a, b) => {
        if (a.original.event === null || b.original.event === null) return;
        return (
          new Date(b.original.event["date_event"]) -
          new Date(a.original.event["date_event"])
        );
      },
    },
    {
      Header: "Horaire event",
      accessor: ({ event }) =>
        event ? `${event.start} - ${event.end}` : "Event horaire not found",
    },
    {
      Header: "Nom",
      accessor: "nom",
    },
    {
      Header: "Prenom",
      accessor: "prenom",
    },
    {
      Header: "Adresse mail",
      accessor: "email",
    },
    {
      Header: "Pharmacie",
      accessor: ({ structurename }) => structurename || "N/A",
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      id: "num",
      Header: "N FINESS",
      accessor: ({ num_finess }) => num_finess || "N/A",
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      id: "nom_du_groupement",
      Header: "Nom du groupement",
      accessor: ({ nom_du_groupement }) => nom_du_groupement || "N/A",
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      Header: "Notif",
      accessor: ({ acceptInfo }) => (acceptInfo ? "1" : "0"),
      // Cell: (cell) => cell.value || "N/A",
    },
    {
      Header: "Done",
      accessor: "checked",
      sortType: (a, b, id) => {
        if (a.original[id] > b.original[id]) return -1;
        if (b.original[id] > a.original[id]) return 1;
      },
      disableGlobalFilter: true,
      Cell: (cell) => (
        <input
          readOnly
          type="checkbox"
          onClick={(e) => {
            action.modifyByIndex(cell.row.index, { checked: !cell.value });
          }}
          checked={cell.value}
        />
      ),
    },
    {
      Header: "action",
      accessor: (a) => a._id,
      disableSortBy: true,
      disableFilters: true,
      Cell: (cell) => (
        <>
          <DeleteButton
            onClick={() => {
              action.remove(cell.value);
            }}
          />
        </>
      ),
    },
  ]);
  useEffect(() => {
    axios("https://api.smartrealxperience-inscription.fr/api/partic/all")
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <section className="container-fluid">
      <h2 style={{ textAlign: "center" }}>DashBoardParticipant</h2>
      <hr />
      <Table
        columns={columns}
        data={data}
        initialState={{ pageIndex: 0 }}
        extra={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetGroupBy: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          autoResetRowState: false,
          autoResetGlobalFilter: false,
        }}
      />
    </section>
  );
}

export default DashBoardParticipant;
